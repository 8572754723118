import { Typography } from "@mui/material";
import { useEffect } from "react";
import { ConfirmModal } from "./ConfirmModal";
import { useGlobalToastNotificationContext } from "../context/GlobalToastNotificationContext";
import { useApiRequest } from "../hooks/useApiRequest";
import { Order } from "../types/order";

type DeleteOrderModalProps = {
  order: Order;
  onClose: () => void;
  onDeleteSuccess: () => void;
};

export const DeleteOrderModal: React.FC<DeleteOrderModalProps> = ({
  order,
  onClose,
  onDeleteSuccess,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();

  const {
    data: deleteOrderData,
    request: deleteOrderRequest,
    loading: deleteOrderLoading,
    status: deleteOrderStatus,
    errorMessage: deleteOrderErrorMessage,
  } = useApiRequest(false);

  useEffect(() => {
    if (
      deleteOrderData &&
      deleteOrderStatus === "ok" &&
      deleteOrderLoading === false
    ) {
      onDeleteSuccess();
    }
  }, [deleteOrderData, deleteOrderLoading, deleteOrderStatus, onDeleteSuccess]);

  useEffect(() => {
    if (deleteOrderErrorMessage) {
      showErrorToast({ message: deleteOrderErrorMessage });
    }
  }, [deleteOrderErrorMessage, showErrorToast]);

  const handleDeleteClick = () => {
    deleteOrderRequest(`/orders/${order.orderGuid}/delete`, {
      method: "DELETE",
    });
  };

  return (
    <ConfirmModal
      isOpen={true}
      heading={
        order.orderStatus === "q" ? "Delete Quote" : "Delete Archived Quote"
      }
      body={
        <Typography variant="body2" color="grey.500">
          Deleting this cannot be undone. Delete it?
        </Typography>
      }
      confirmButtonText="Delete"
      isDestructive={true}
      onConfirm={handleDeleteClick}
      onClose={onClose}
      confirmDisabled={deleteOrderLoading}
    />
  );
};
