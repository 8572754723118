import { Markup } from "./markup";
import { RetailerManufacturer } from "./retailerManufacturer";
import { User } from "./user";

export type Tenant = {
  ownerUser: User | null;
  tenantAddress1: string;
  tenantAddress2: string;
  tenantCity: string;
  tenantState: string;
  tenantZip: string;
  tenantGuid: string;
  tenantName: string;
  tenantPhoneNumber: string;
  tenantPhotoUrl: string | null;
  tenantStartingInvoiceNumber: string;
  tenantType: "m" | "r";
  tenantWebsite: string;
  isActive: boolean;
  errors: [
    "company",
    "models",
    "attributes",
    "retailers",
    "vendors",
    "users",
    "templates",
    "freights",
    "disclosures",
    "statefees",
    "surcharges",
    "logics"
  ];
  nextVendorIdentifier: string;
  retailerFriendlyName: string | null;
  retailerEinTin: string | null;
  retailerLicenseNumber: string | null;
  retailerShippingAddress1: string | null;
  retailerShippingAddress2: string | null;
  retailerShippingCity: string | null;
  retailerShippingState: string | null;
  retailerShippingZip: string | null;
  businessRelation: RetailerManufacturer | null;
  canEdit: boolean;
  retailers: Tenant[] | null;
  mfgNameWhoCanEdit: string | null;
  markup: Markup[] | null;
  applyPercentToConstCosts: boolean;
  includeReqHidConstCostsInHomePrice: boolean;
  hudFee: number;
  modFee: number;
};

export type TenantErrors = Tenant["errors"][number];

export type Retailer = Tenant;

export const getTenantInitials = (
  tenant: Pick<Tenant, "tenantName">
): string => {
  if (!tenant?.tenantName) {
    return "";
  }
  const allNames = tenant.tenantName.trim().split(" ");
  if (allNames.length === 0) {
    return "";
  } else if (allNames.length === 1) {
    return allNames[0].charAt(0).toUpperCase();
  } else {
    const firstInitial = allNames[0].charAt(0).toUpperCase();
    const lastInitial = allNames[allNames.length - 1].charAt(0).toUpperCase();
    return `${firstInitial} ${lastInitial}`;
  }
};

export const getTenantPhotoUrlSrc = (
  tenant: Pick<Tenant, "tenantPhotoUrl">
): string | undefined => {
  return tenant?.tenantPhotoUrl
    ? `${process.env.REACT_APP_API_BASE_URL}/${tenant.tenantPhotoUrl}`
    : undefined;
};

export const getTenantBusinessAddress = (
  tenant: Pick<
    Tenant,
    | "tenantAddress1"
    | "tenantAddress2"
    | "tenantCity"
    | "tenantState"
    | "tenantZip"
  >
): string => {
  const streetAddress = [tenant.tenantAddress1, tenant.tenantAddress2]
    .filter(Boolean)
    .join(" ");

  const addressParts = [
    streetAddress,
    tenant.tenantCity,
    tenant.tenantState,
    tenant.tenantZip,
  ].filter(Boolean);

  return addressParts.join(", ");
};
