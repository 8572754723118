import React, { useCallback, useEffect, useRef } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import {
  Stack,
  Button,
  Card,
  CardContent,
  Box,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Series } from "../../types/series";
import { PageHeader } from "../../components/PageHeader";
import { useForm } from "react-hook-form";
import {
  getNumBathrooms,
  getNumBedrooms,
  getNumHalfBathrooms,
} from "../../types/modelRoom";
import {
  getGrossWeight,
  getHitchLength,
  getLivingSpace,
  getNonHeatedSpace,
  getUnitHeight,
  getUnitLength,
  getUnitWidth,
} from "../../types/section";
import { Model } from "../../types/model";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { HomeDetailsFormSection } from "./HomeDetailsFormSection";
import { HomeSizeFormSection } from "./HomeSizeFormSection";
import { RoomsFormSection } from "./RoomsFormSection";
import { SectionsFormSection } from "./SectionsFormSection";
import { TotalsRow } from "./TotalsRow";
import { FormData } from "./FormData";
import { AttachFile } from "@mui/icons-material";
import { ModelAttachments } from "./ModelAttachments";
import { DropzoneRef } from "react-dropzone";

const getDefaultValues = (model: Model | null): FormData => {
  return {
    modelName: model?.modelName ?? "",
    modelNumber: model?.modelNumber ?? "",
    startDate: model?.startDate ? DateTime.fromSeconds(model?.startDate) : null,
    endDate: model?.endDate ? DateTime.fromSeconds(model?.endDate) : null,
    modelBasePrice: model?.modelBasePrice,
    modelConstructionType: model?.modelConstructionType ?? "",
    modelRooms: model?.modelRooms ?? [],
    sections: model?.sections ?? [],
  };
};

const ManageModelSeriesAddEditRoute: React.FC = () => {
  const dropzoneRef = useRef<DropzoneRef>(null);
  const [numberOfAttachments, setNumberOfAttachments] = React.useState<
    number | null
  >(null);
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showErrorToast, showSuccessToast } =
    useGlobalToastNotificationContext();
  const { seriesGuid, modelGuid } = useParams();

  const { data: series, request: seriesRequest } = useApiRequest<Series>(false);
  const {
    data: modelData,
    request: modelRequest,
    loading: modelLoading,
    status: modelStatus,
  } = useApiRequest<Model>(false);
  const {
    data: modelCreateData,
    request: modelCreateRequest,
    loading: modelCreateLoading,
    status: modelCreateStatus,
    error: modelCreateError,
  } = useApiRequest<Model>(false);
  const {
    data: modelEditData,
    request: modelEditRequest,
    loading: modelEditLoading,
    status: modelEditStatus,
    error: modelEditError,
  } = useApiRequest<Model>(false);
  const {
    data: modelCopyData,
    request: modelCopyRequest,
    loading: modelCopyLoading,
    status: modelCopyStatus,
    error: modelCopyError,
  } = useApiRequest<Model>(false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    formState,
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(modelData),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const modelName = watch("modelName");
  const modelRooms = watch("modelRooms");
  const sections = watch("sections");

  const numBedrooms = getNumBedrooms(modelRooms);
  const numBathrooms = getNumBathrooms(modelRooms);
  const numHalfBathrooms = getNumHalfBathrooms(modelRooms);
  const numTotalRooms = modelRooms.length;
  const numUnitWidth = getUnitWidth(sections);
  const numUnitLength = getUnitLength(sections);
  const numHitchLength = getHitchLength(sections);
  const numUnitHeight = getUnitHeight(sections);
  const dimensionsWidth = numUnitWidth;
  const dimensionsLength = numUnitLength + numHitchLength;
  const livingSpace = getLivingSpace(sections);
  const nonHeatedSpace = getNonHeatedSpace(sections);
  const grossWeight = getGrossWeight(sections);
  const totalSpace = livingSpace + nonHeatedSpace;

  const seriesName = series?.seriesName ?? "";

  usePageMetadata({
    title: modelGuid
      ? `Manage ${routes.manageDataModelSeries.label} ${seriesName} Edit Model`
      : `Manage ${routes.manageDataModelSeries.label} ${seriesName} Create Model`,
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        label: routes.manageDataModelSeries.label,
        href: routes.manageDataModelSeries.path,
      },
      { label: seriesName },
    ]);
  }, [seriesName, setBreadcrumbs]);

  const refreshModel = useCallback(() => {
    if (modelGuid) {
      modelRequest(`/company/models/${modelGuid}`, { method: "GET" });
    }
  }, [modelGuid, modelRequest]);

  useEffect(() => {
    refreshModel();
  }, [refreshModel]);

  useEffect(() => {
    seriesRequest(`/company/series/${seriesGuid}`, { method: "GET" });
  }, [seriesGuid, seriesRequest]);

  useEffect(() => {
    if (modelData && modelStatus === "ok" && modelLoading === false) {
      const defaultValues = getDefaultValues(modelData);
      reset(defaultValues);
    }
  }, [modelLoading, modelStatus, modelData, reset]);

  useEffect(() => {
    if (
      modelCreateData &&
      modelCreateStatus === "ok" &&
      modelCreateLoading === false
    ) {
      showSuccessToast({ message: "Model created!" });
      navigate(
        generatePath(routes.manageDataModelSeriesModelsEdit.path, {
          seriesGuid: seriesGuid ?? "",
          modelGuid: modelCreateData.modelGuid,
        })
      );
    } else if (modelCreateError != null) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    modelCreateData,
    modelCreateError,
    modelCreateLoading,
    modelCreateStatus,
    navigate,
    seriesGuid,
    showErrorToast,
    showSuccessToast,
  ]);

  useEffect(() => {
    if (
      modelEditData &&
      modelEditStatus === "ok" &&
      modelEditLoading === false
    ) {
      showSuccessToast({ message: "Model saved!" });
      navigate(routes.manageDataModelSeries.path);
    } else if (modelEditError != null) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    modelEditData,
    modelEditError,
    modelEditLoading,
    modelEditStatus,
    navigate,
    showErrorToast,
    showSuccessToast,
  ]);

  useEffect(() => {
    if (
      modelCopyData &&
      modelCopyStatus === "ok" &&
      modelCopyLoading === false
    ) {
      showSuccessToast({ message: "Model copied!" });
      navigate(
        generatePath(routes.manageDataModelSeriesModelsEdit.path, {
          seriesGuid: seriesGuid ?? "",
          modelGuid: modelCopyData.modelGuid,
        })
      );
    } else if (modelCopyError != null) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    showErrorToast,
    showSuccessToast,
    modelCopyData,
    modelCopyError,
    modelCopyLoading,
    modelCopyStatus,
    navigate,
    seriesGuid,
  ]);

  const onSubmit = (data: FormData) => {
    if (modelGuid) {
      modelEditRequest(`company/models/${modelGuid}/edit`, {
        method: "POST",
        data: data,
      });
    } else {
      modelCreateRequest(`/company/series/${seriesGuid}/models/add`, {
        method: "POST",
        data: data,
      });
    }
  };

  const handleCancelClick = () => {
    navigate(routes.manageDataModelSeries.path);
  };

  const handleCopyModelClick = () => {
    modelCopyRequest(`company/models/copy`, {
      method: "POST",
      data: { modelGuid },
    });
  };

  const handleAttachFile = () => {
    dropzoneRef.current?.open();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title={modelName}
          actions={
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: 1, minWidth: "initial" }}
                disabled={!modelGuid || modelCopyLoading || formState.isDirty}
                onClick={handleCopyModelClick}
              >
                <ContentCopyIcon aria-label="Copy" />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  paddingLeft: 1.5,
                  paddingRight: 1.5,
                  minWidth: "initial",
                }}
                disabled={!modelGuid || modelCopyLoading}
                onClick={handleAttachFile}
              >
                <Stack direction={"row"}>
                  <AttachFile
                    aria-label="Attach"
                    sx={{
                      transform: "rotate(45deg)",
                    }}
                  />
                  {numberOfAttachments}
                </Stack>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  !formState.isValid || modelCreateLoading || modelCopyLoading
                }
              >
                {modelGuid ? "Save" : "Create"}
              </Button>
            </Stack>
          }
        />
        <TotalsRow
          dimensionsWidth={dimensionsWidth}
          dimensionsLength={dimensionsLength}
          livingSpace={livingSpace}
          nonHeatedSpace={nonHeatedSpace}
          totalSpace={totalSpace}
          grossWeight={grossWeight}
        />
        <Grid container spacing={2}>
          <Grid xs={12} sm={8}>
            <Card variant="outlined">
              <CardContent>
                <HomeDetailsFormSection
                  register={register}
                  control={control}
                  trigger={trigger}
                  errors={formState.errors}
                  seriesName={seriesName}
                  numBedrooms={numBedrooms}
                  numBathrooms={numBathrooms}
                  numHalfBathrooms={numHalfBathrooms}
                  numTotalRooms={numTotalRooms}
                />
                <HomeSizeFormSection
                  numUnitWidth={numUnitWidth}
                  numUnitLength={numUnitLength}
                  numHitchLength={numHitchLength}
                  numUnitHeight={numUnitHeight}
                />
                <RoomsFormSection
                  modelRooms={modelRooms}
                  control={control}
                  setValue={setValue}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12} sm={4}>
            <SectionsFormSection
              sections={sections}
              control={control}
              formState={formState}
              register={register}
              setValue={setValue}
            />
            <Box marginTop={5}>
              <Typography variant="body1" fontWeight={600} mb={1}>
                Attachments
              </Typography>
              <ModelAttachments
                modelGuid={modelGuid}
                onFileListChanged={(attachments) =>
                  setNumberOfAttachments(attachments.length)
                }
                dropzoneRef={dropzoneRef}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ManageModelSeriesAddEditRoute;
