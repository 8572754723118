import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  Retailer,
  getTenantBusinessAddress,
  getTenantPhotoUrlSrc,
} from "../../types/tenant";
import { PageHeader } from "../../components/PageHeader";
import { useForm } from "react-hook-form";
import { AvatarUpload } from "../../components/AvatarUpload";
import { TabPanel, tabA11yProps } from "../../components/TabPanel";
import { FormData } from "./FormData";
import { CompanyInfoTab } from "./CompanyInfoTab";
import { User } from "../../types/user";
import { useSession } from "../../hooks/useSession";
import { UserRole, UserRoles } from "../../types/userRole";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { RetailerManufacturer } from "../../types/retailerManufacturer";
import { RetailerNote } from "../../types/retailerNote";
import { InventoryTab } from "./InventoryTab";

const getDefaultValues = (retailer: Retailer | null): FormData => {
  return {
    tenantName: retailer?.tenantName ?? null,
    retailerFriendlyName: retailer?.retailerFriendlyName ?? null,
    retailerEinTin: retailer?.retailerEinTin ?? null,
    tenantPhoneNumber: retailer?.tenantPhoneNumber ?? null,
    tenantWebsite: retailer?.tenantWebsite ?? null,
    tenantAddress1: retailer?.tenantAddress1 ?? null,
    tenantAddress2: retailer?.tenantAddress2 ?? null,
    tenantCity: retailer?.tenantCity ?? null,
    tenantState: retailer?.tenantState ?? "",
    tenantZip: retailer?.tenantZip ?? null,
    retailerLicenseNumber: retailer?.retailerLicenseNumber ?? null,
    retailerShippingAddress1: retailer?.retailerShippingAddress1 ?? null,
    retailerShippingAddress2: retailer?.retailerShippingAddress2 ?? null,
    retailerShippingCity: retailer?.retailerShippingCity ?? null,
    retailerShippingState: retailer?.retailerShippingState ?? "",
    retailerShippingZip: retailer?.retailerShippingZip ?? null,
    businessRelation: {
      isActive: retailer?.businessRelation?.isActive ?? true,
      manufacturerSalesRepUserGuid:
        retailer?.businessRelation?.manufacturerSalesRepUserGuid ?? "",
      manufacturerRetailerIdentifier:
        retailer?.businessRelation?.manufacturerRetailerIdentifier ?? null,
      manufacturerDefaultVepLevel:
        retailer?.businessRelation?.manufacturerDefaultVepLevel ?? 0,
      percentPerBasePrice:
        retailer?.businessRelation?.percentPerBasePrice ?? null,
      amountPerFloor: retailer?.businessRelation?.amountPerFloor ?? null,
      amountPerUnit: retailer?.businessRelation?.amountPerUnit ?? null,
    },
  };
};

const tabs = {
  companyInfo: 0,
  inventory: 1,
} as const;
type TabValue = (typeof tabs)[keyof typeof tabs];

const ManageRetailersDetailRoute: React.FC = () => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const { tenantGuid } = useParams<{ tenantGuid: string }>();
  const { user } = useSession();
  const [retailer, setRetailer] = useState<Retailer | null>(null);
  const tabsAriaLabel = "retailers-tabs";
  const [tabValue, setTabValue] = React.useState<TabValue>(tabs.companyInfo);

  const {
    data: retailerData,
    loading: retailerLoading,
    request: retailerRequest,
    status: retailerStatus,
  } = useApiRequest<Retailer>(false);

  const { data: usersData, request: usersRequest } =
    useApiRequest<User[]>(false);

  const {
    data: retailerUpdatePhotoData,
    loading: retailerUpdatePhotoLoading,
    request: retailerUpdatePhotoRequest,
    status: retailerUpdatePhotoStatus,
    errorMessage: retailerUpdatePhotoErrorMessage,
  } = useApiRequest<Retailer>(false);

  const {
    data: businessRelationUpdateData,
    loading: businessRelationUpdateLoading,
    request: businessRelationUpdateRequest,
    status: businessRelationUpdateStatus,
    error: businessRelationUpdateError,
  } = useApiRequest<RetailerManufacturer>(false);

  const {
    data: retailerUpdateData,
    loading: retailerUpdateLoading,
    request: retailerUpdateRequest,
    status: retailerUpdateStatus,
    error: retailerUpdateError,
  } = useApiRequest<Retailer>(false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    resetField,
    formState,
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(retailer),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const manufacturerRetailerIdentifier = watch(
    "businessRelation.manufacturerRetailerIdentifier"
  );
  const tenantAddress1 = watch("tenantAddress1");
  const tenantAddress2 = watch("tenantAddress2");
  const tenantCity = watch("tenantCity");
  const tenantState = watch("tenantState");
  const tenantZip = watch("tenantZip");
  const tenantPhoneNumber = watch("tenantPhoneNumber");

  const canSaveRetailerData = useMemo(
    () =>
      user != null &&
      (
        [UserRole.OWNER, UserRole.ADMIN, UserRole.ADVANCEDUSER] as UserRoles[]
      ).includes(user.userRole),
    [user]
  );

  usePageMetadata({ title: `Manage ${routes.manageDataRetailerDetail.label}` });

  useEffect(() => {
    setBreadcrumbs([
      {
        label: routes.manageDataRetailers.label,
        href: routes.manageDataRetailers.path,
      },
      { label: retailer?.tenantName ?? "" },
    ]);
  }, [retailer?.tenantName, setBreadcrumbs]);

  const refreshRetailer = useCallback(() => {
    if (tenantGuid) {
      retailerRequest(`/company/retailers/${tenantGuid}`, { method: "GET" });
    }
  }, [retailerRequest, tenantGuid]);

  useEffect(() => {
    refreshRetailer();
  }, [refreshRetailer]);

  useEffect(() => {
    setRetailer(retailerData);
  }, [retailerData]);

  useEffect(() => {
    if (retailerData && retailerStatus === "ok" && retailerLoading === false) {
      const defaultValues = getDefaultValues(retailerData);
      reset(defaultValues, { keepDirtyValues: true });
    }
  }, [retailerLoading, retailerStatus, retailerData, reset]);

  useEffect(() => {
    usersRequest(`/users`, { method: "GET" });
  }, [usersRequest]);

  useEffect(() => {
    if (
      retailerUpdatePhotoData &&
      retailerUpdatePhotoStatus === "ok" &&
      retailerUpdatePhotoLoading === false
    ) {
      setRetailer((prevRetailer) => ({
        ...prevRetailer!,
        tenantPhotoUrl: retailerUpdatePhotoData.tenantPhotoUrl,
      }));
    } else if (retailerUpdatePhotoErrorMessage) {
      showErrorToast({ message: retailerUpdatePhotoErrorMessage });
    }
  }, [
    showErrorToast,
    retailerUpdatePhotoData,
    retailerUpdatePhotoErrorMessage,
    retailerUpdatePhotoLoading,
    retailerUpdatePhotoStatus,
  ]);

  useEffect(() => {
    const retailerUpdateIsSuccess =
      retailerUpdateData &&
      retailerUpdateStatus === "ok" &&
      retailerUpdateLoading === false;

    const businessRelationUpdateIsSuccess =
      businessRelationUpdateData &&
      businessRelationUpdateStatus === "ok" &&
      businessRelationUpdateLoading === false;

    if (retailerUpdateError || businessRelationUpdateError) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    } else if (retailerUpdateIsSuccess && businessRelationUpdateIsSuccess) {
      const updatedRetailer: Retailer = {
        ...retailerUpdateData,
        businessRelation: { ...businessRelationUpdateData },
      };
      setRetailer(updatedRetailer);
      const defaultValues = getDefaultValues(updatedRetailer);
      reset(defaultValues);
    } else if (businessRelationUpdateIsSuccess) {
      setRetailer((prevRetailer) => {
        if (!prevRetailer) {
          return prevRetailer;
        }
        return {
          ...prevRetailer,
          businessRelation: { ...businessRelationUpdateData },
        };
      });
      resetField("businessRelation.isActive", {
        defaultValue: businessRelationUpdateData.isActive,
      });
      resetField("businessRelation.manufacturerSalesRepUserGuid", {
        defaultValue:
          businessRelationUpdateData.manufacturerSalesRepUserGuid ?? "",
      });
      resetField("businessRelation.manufacturerRetailerIdentifier", {
        defaultValue: businessRelationUpdateData.manufacturerRetailerIdentifier,
      });
      resetField("businessRelation.manufacturerDefaultVepLevel", {
        defaultValue: businessRelationUpdateData.manufacturerDefaultVepLevel,
      });
    }
  }, [
    businessRelationUpdateData,
    businessRelationUpdateError,
    businessRelationUpdateLoading,
    businessRelationUpdateStatus,
    reset,
    resetField,
    showErrorToast,
    retailerUpdateData,
    retailerUpdateError,
    retailerUpdateLoading,
    retailerUpdateStatus,
  ]);

  const handleSelectFile = (file: File) => {
    retailerUpdatePhotoRequest(`/company/retailers/${tenantGuid}/edit`, {
      method: "POST",
      data: { tenantPhoto: file },
    });
  };

  const handleTabChange = (_: React.SyntheticEvent, newTabValue: TabValue) => {
    setTabValue(newTabValue);
  };

  const handleAddNote = useCallback((note: RetailerNote) => {
    setRetailer((prevRetailer) => {
      if (!prevRetailer || !prevRetailer.businessRelation) {
        return prevRetailer;
      }

      return {
        ...prevRetailer,
        businessRelation: {
          ...prevRetailer.businessRelation,
          retailerNotes: [...prevRetailer.businessRelation.retailerNotes, note],
        },
      };
    });
  }, []);

  const handleAttachmentsChanged = useCallback(() => {
    refreshRetailer();
  }, [refreshRetailer]);

  const handleCancelClick = () => {
    navigate(generatePath(routes.manageDataRetailers.path));
  };

  const onSubmit = (data: FormData) => {
    const { businessRelation, ...retailerData } = data;

    if (retailer?.canEdit) {
      retailerUpdateRequest(`/company/retailers/${tenantGuid}/edit`, {
        method: "POST",
        data: retailerData,
      });
    }
    businessRelationUpdateRequest(
      `/company/retailers/${tenantGuid}/businessrelation`,
      {
        method: "POST",
        data: businessRelation,
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader
        title={
          <Stack direction="row" spacing={2}>
            <AvatarUpload
              readOnly={!retailer?.canEdit}
              alt="Company Image"
              src={retailer ? getTenantPhotoUrlSrc(retailer) : undefined}
              variant="circular"
              avatarSx={{ height: 93, width: 93 }}
              onSelectFile={handleSelectFile}
            />
            <Stack>
              <Typography variant="h6">{retailer?.tenantName}</Typography>
              <Typography variant="subtitle2" color="grey.500">
                ID {manufacturerRetailerIdentifier}
              </Typography>
              <br />
              <Typography
                variant="caption"
                color="grey.500"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CallOutlinedIcon sx={{ fontSize: 16, marginRight: 1 }} />
                {tenantPhoneNumber}
              </Typography>
              <Typography
                variant="caption"
                color="grey.500"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlinedIcon sx={{ fontSize: 16, marginRight: 1 }} />
                {getTenantBusinessAddress({
                  tenantAddress1: tenantAddress1 ?? "",
                  tenantAddress2: tenantAddress2 ?? "",
                  tenantCity: tenantCity ?? "",
                  tenantState: tenantState ?? "",
                  tenantZip: tenantZip ?? "",
                })}
              </Typography>
            </Stack>
          </Stack>
        }
        actions={
          canSaveRetailerData ? (
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  !formState.isValid ||
                  !formState.isDirty ||
                  retailerLoading ||
                  retailerUpdateLoading ||
                  businessRelationUpdateLoading
                }
              >
                Save
              </Button>
            </Stack>
          ) : undefined
        }
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label={tabsAriaLabel}
        >
          <Tab
            label="Company Info"
            {...tabA11yProps(tabsAriaLabel, tabs.companyInfo)}
          />
          <Tab
            label="Inventory"
            {...tabA11yProps(tabsAriaLabel, tabs.inventory)}
          />
        </Tabs>
      </Box>
      <TabPanel
        value={tabValue}
        index={tabs.companyInfo}
        tabsAriaLabel={tabsAriaLabel}
      >
        <CompanyInfoTab
          retailer={retailer}
          salesReps={usersData ?? []}
          tenantGuid={tenantGuid}
          control={control}
          formState={formState}
          register={register}
          onAddNote={handleAddNote}
          onAttachmentsChanged={handleAttachmentsChanged}
        />
      </TabPanel>
      <TabPanel
        value={tabValue}
        index={tabs.inventory}
        tabsAriaLabel={tabsAriaLabel}
      >
        <InventoryTab retailer={retailer} />
      </TabPanel>
    </form>
  );
};

export default ManageRetailersDetailRoute;
