import { useCallback, useEffect, useState } from "react";
import { Box, Card, Stack } from "@mui/material";
import { DropZone } from "./DropZone";
import { AttachmentList } from "./AttachmentList";
import { Attachment } from "../types/attachment";
import { Accept, DropzoneRef } from "react-dropzone";
import { useApiRequest } from "../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../context/GlobalToastNotificationContext";

const defaultAcceptedFileExtensions: Accept = {
  "image/jpeg": [],
  "image/jpg": [],
  "image/png": [],
  "application/pdf": [],
  "application/zip": [],
  // Word
  "application/msword": [],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
  // Excel
  "application/vnd.ms-excel": [],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

type FileManagerProps = {
  accept?: Accept;
  onFileListChanged?: (attachments: Attachment[]) => void;
  disabled?: boolean;
  disabledText?: string;
  attachments: Attachment[];
  onRefreshAttachments: () => void;
  createAttachmentUrl: string;
  deleteAttachmentUrl?: (attachment: Attachment) => string;
  downloadAttachmentUrl?: (attachment: Attachment) => string;
  dropzoneRef?: React.RefObject<DropzoneRef>;
};

export const AttachmentManager: React.FC<FileManagerProps> = ({
  accept = defaultAcceptedFileExtensions,
  onFileListChanged,
  disabled,
  disabledText,
  createAttachmentUrl,
  deleteAttachmentUrl,
  downloadAttachmentUrl,
  dropzoneRef,
  attachments,
  onRefreshAttachments,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [downloadingFile, setDownloadingFile] = useState<Attachment | null>(
    null
  );
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);

  const {
    request: downloadRequest,
    loading: downloadPending,
    data: downloadedFile,
    errorMessage: downloadErrorMessage,
  } = useApiRequest<Blob>();

  const {
    request: uploadRequest,
    loading: uploadLoading,
    data: uploadResponse,
    errorMessage: uploadErrorMessage,
  } = useApiRequest();

  const {
    request: deleteRequest,
    loading: deletePending,
    data: deleteResponse,
    errorMessage: deleteErrorMessage,
  } = useApiRequest();

  const refreshAttachments = useCallback(() => {
    if (disabled) return;
    onRefreshAttachments();
  }, [disabled, onRefreshAttachments]);

  const onDrop = (acceptedFiles: File[]) => {
    setUploadingFiles(acceptedFiles);
  };

  const handleDownload = (attachment: Attachment) => {
    if (!downloadAttachmentUrl) return;
    setDownloadingFile(attachment);
    downloadRequest(downloadAttachmentUrl(attachment), {
      method: "GET",
      responseType: "blob",
    });
  };

  const handleDelete = (attachment: Attachment) => {
    if (!deleteAttachmentUrl) return;
    deleteRequest(deleteAttachmentUrl(attachment), {
      method: "DELETE",
    });
  };

  // Upload the first file in the queue when we have a queue
  useEffect(() => {
    if (uploadingFiles.length > 0) {
      uploadRequest(createAttachmentUrl, {
        method: "POST",
        data: {
          attachmentFile: uploadingFiles[0],
        },
      });
    }
  }, [uploadingFiles, createAttachmentUrl, uploadRequest]);

  // Then remove the first file from the queue when it has been uploaded or an error occurred.
  useEffect(() => {
    if (uploadResponse || uploadErrorMessage) {
      setUploadingFiles((before) => before.slice(1));
    }
  }, [uploadResponse, uploadErrorMessage]);

  useEffect(() => {
    refreshAttachments();
  }, [refreshAttachments, uploadResponse, deleteResponse]);

  useEffect(() => {
    if (downloadedFile) {
      const url = window.URL.createObjectURL(downloadedFile);
      const link = document.createElement("a");
      link.href = url;
      link.download = downloadingFile?.attachmentFilename || "file";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }, [downloadedFile, downloadPending, downloadingFile?.attachmentFilename]);

  useEffect(() => {
    if (onFileListChanged) {
      onFileListChanged(attachments ?? []);
    }
  }, [attachments, onFileListChanged]);

  useEffect(() => {
    if (uploadErrorMessage) {
      showErrorToast({ title: "Upload Error", message: uploadErrorMessage });
    }
  }, [uploadErrorMessage, showErrorToast]);

  useEffect(() => {
    if (deleteErrorMessage) {
      showErrorToast({ message: deleteErrorMessage });
    }
  }, [deleteErrorMessage, showErrorToast]);

  useEffect(() => {
    if (downloadErrorMessage) {
      showErrorToast({ message: downloadErrorMessage });
    }
  }, [downloadErrorMessage, showErrorToast]);

  return (
    <Card variant="outlined">
      <Stack rowGap={2}>
        <AttachmentList
          attachments={attachments || []}
          onDelete={deleteAttachmentUrl ? handleDelete : undefined}
          onDownload={downloadAttachmentUrl ? handleDownload : undefined}
          loading={uploadLoading || deletePending}
        />
        <Box p={3}>
          <DropZone
            onDrop={onDrop}
            accept={accept}
            loading={uploadLoading || uploadingFiles.length > 0}
            disabled={disabled || uploadLoading || uploadingFiles.length > 0}
            disabledText={disabledText}
            dropzoneRef={dropzoneRef}
          />
        </Box>
      </Stack>
    </Card>
  );
};
