import { Tenant } from "./types/tenant";
import { UserSession } from "./types/user";
import { UserRole, UserRoles } from "./types/userRole";

export type RouteConfig = {
  /**
   * The label for the page.
   */
  label: string;
  /**
   * The url path.
   */
  path: string;
  /**
   * The roles (if any) the user should have in order to access this page.
   */
  roles: readonly UserRoles[];
  /**
   * Optional. If provided the user must be this tenant type in order to access this page.
   */
  tenantType?: Tenant["tenantType"];
};

export const routes = {
  home: {
    label: "Home",
    path: "/",
    roles: [],
  },
  reporting: {
    label: "Reporting",
    path: "/reporting",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
  },
  reportingAdd: {
    label: "Reporting Add",
    path: "/reporting/add",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
  },
  reportingEdit: {
    label: "Reporting Edit",
    path: "/reporting/:reportGuid/edit",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
  },
  profile: {
    label: "Update Profile",
    path: "/profile",
    roles: [],
  },
  quotesAndOrders: {
    label: "Quotes & Orders",
    path: "/company/:tenantGuid/quotes",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
  },
  quotesAndOrdersDetail: {
    label: "Quotes & Orders",
    path: "/company/:tenantGuid/quotes/:orderGuid",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
  },
  manageTenants: {
    label: "Tenants",
    path: "/manage/tenants",
    roles: [UserRole.SYSADMIN],
  },
  manageSystemAdmins: {
    label: "System Admins",
    path: "/manage/sysadmins",
    roles: [UserRole.SYSADMIN],
  },
  manageData: {
    label: "Manage Data",
    path: "/manage/data",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
  },
  manageDataCompany: {
    label: "Company Info",
    path: "/manage/data/company",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataModelSeries: {
    label: "Model/Series",
    path: "/manage/data/model-series",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataModelSeriesModelsAdd: {
    label: "Model/Series",
    path: "/manage/data/model-series/:seriesGuid/models/add",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataModelSeriesModelsEdit: {
    label: "Model/Series",
    path: "/manage/data/model-series/:seriesGuid/models/:modelGuid",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataSelectionOptions: {
    label: "Selection Options",
    path: "/manage/data/selection-options",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataSelectionOptionsSubGroup: {
    label: "Selection Options",
    path: "/manage/data/selection-options/:subgroupGuid",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataSelectionOptionsAdd: {
    label: "Selection Options",
    path: "/manage/data/selection-options/:subgroupGuid/add",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataSelectionOptionsEdit: {
    label: "Selection Options",
    path: "/manage/data/selection-options/:subgroupGuid/options/:optionGuid/edit",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataAttributes: {
    label: "Attributes",
    path: "/manage/data/attributes",
    roles: [UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataAttributesDetail: {
    label: "Attributes",
    path: "/manage/data/attributes/:attributeGuid",
    roles: [UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataRetailers: {
    label: "Retailers",
    path: "/manage/data/retailers",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
    tenantType: "m",
  },
  manageDataRetailerDetail: {
    label: "Retailers",
    path: "/manage/data/retailers/:tenantGuid",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
    tenantType: "m",
  },
  manageDataVendors: {
    label: "Vendors",
    path: "/manage/data/vendors",
    roles: [UserRole.ADVANCEDUSER, UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataUsers: {
    label: "Users",
    path: "/manage/data/users",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataTemplates: {
    label: "Templates",
    path: "/manage/data/templates",
    roles: [
      UserRole.BASICUSER,
      UserRole.ADVANCEDUSER,
      UserRole.OWNER,
      UserRole.ADMIN,
    ],
    tenantType: "m",
  },
  manageDataFreight: {
    label: "Freight",
    path: "/manage/data/freight",
    roles: [UserRole.ADVANCEDUSER, UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataInvoiceDisclosures: {
    label: "Invoice Disclosures",
    path: "/manage/data/invoice-disclosures",
    roles: [UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataStateFees: {
    label: "State Fees",
    path: "/manage/data/state-fees",
    roles: [UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataSurchargeCalculator: {
    label: "Surcharge Calculator",
    path: "/manage/data/surcharge-calculator",
    roles: [UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataConditionalLogic: {
    label: "Conditional Logic",
    path: "/manage/data/conditional-logic",
    roles: [UserRole.OWNER, UserRole.ADMIN],
    tenantType: "m",
  },
  manageDataConditionalLogicAdd: {
    label: "Conditional Logic",
    path: "/manage/data/conditional-logic/add",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  manageDataConditionalLogicEdit: {
    label: "Conditional Logic",
    path: "/manage/data/conditional-logic/:logicGuid/edit",
    roles: [UserRole.OWNER, UserRole.ADMIN],
  },
  tenantUsers: {
    label: "Users",
    path: "/manage/data/tenants/:tenantGuid/users",
    roles: [UserRole.SYSADMIN],
  },
  login: {
    label: "Login",
    path: "/login",
    roles: [],
  },
  resetPassword: {
    label: "Reset Password",
    path: "/users/resetpassword",
    roles: [],
  },
  buyerMode: {
    label: "Buyer Mode",
    path: "/buyer-mode",
    roles: [],
  },
  terms: {
    label: "Terms of Use",
    path: "/terms",
    roles: [],
  },
} as const satisfies Record<string, RouteConfig>;

export const canAccessRoute = (
  user: UserSession,
  routeConfig: Pick<RouteConfig, "roles" | "tenantType">
) => {
  // 1. Does the user have any roles?
  let canAccess = routeConfig.roles.includes(user.userRole);

  // 2. If the route enforces a specific tenant type, make sure the user is that specific type.
  if (canAccess && routeConfig.tenantType != null) {
    switch (routeConfig.tenantType) {
      case "m":
        canAccess = !user.isRetailUser;
        break;

      case "r":
        canAccess = user.isRetailUser;
        break;

      default:
        // Ensures we handle every routeConfig.tenantType otherwise typescript will report an error.
        const _exhaustiveCheck: never = routeConfig.tenantType;
        return _exhaustiveCheck;
    }
  }

  return canAccess;
};
