import { Attachment } from "../../types/attachment";
import { DropzoneRef } from "react-dropzone";
import { AttachmentManager } from "../../components/AttachmentManager";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useCallback } from "react";

type ModelAttachmentsProps = {
  modelGuid?: string;
  onFileListChanged: (attachments: Attachment[]) => void;
  dropzoneRef?: React.RefObject<DropzoneRef>;
};

export const ModelAttachments: React.FC<ModelAttachmentsProps> = ({
  modelGuid,
  onFileListChanged,
  dropzoneRef,
}) => {
  const { request: getAttachmentsRequest, data: attachments } =
    useApiRequest<Attachment[]>(false);

  const handleRefreshAttachments = useCallback(() => {
    getAttachmentsRequest(`/company/models/${modelGuid}/attachments`, {
      method: "GET",
    });
  }, [getAttachmentsRequest, modelGuid]);

  const buildDeleteUrl = (attachment: Attachment) => {
    return `/company/attachments/${attachment.attachmentGuid}/delete`;
  };

  const buildDownloadUrl = (attachment: Attachment) => {
    return attachment.attachmentUrl;
  };

  return (
    <AttachmentManager
      disabled={!modelGuid}
      createAttachmentUrl={`/company/models/${modelGuid}/attach`}
      deleteAttachmentUrl={buildDeleteUrl}
      downloadAttachmentUrl={buildDownloadUrl}
      onFileListChanged={onFileListChanged}
      disabledText="Model must be saved before uploading attachments"
      dropzoneRef={dropzoneRef}
      attachments={attachments ?? []}
      onRefreshAttachments={handleRefreshAttachments}
    />
  );
};
